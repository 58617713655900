import React, { useState, useEffect } from "react"
import { useLazyQuery, useMutation } from '@apollo/client';
import { Field } from 'react-final-form'
import Wizard from '../components/Wizard'
import logoImage from "../images/logo.png";
import SURVEY_FORM_DATA from "../queries/surveyForm"
import SURVEY_DETAIL from "../queries/savedSurveyDetail"
import SAVE_FORM_MUTATION from "../queries/saveFormMutation"
import VERIFY_AC_USER_MUTATION from "../queries/verifyUserMutation"
import {Buffer} from 'buffer';
//import SocialShare from "../components/Share"

const required = value => (value ? undefined : 'Required')

const SurveyForm = ({location}) => {

    //console.log('First : ' +new Date().toLocaleString())

    const [userVerified, setUserVerified] = useState(null)
    const [userData, setVerifiedUserData] = useState(null)
    const [primaryInterest, setPrimaryInterest] = useState("")
    const [formData, setFormData] = useState(null)
    const [status, setStatus] = useState('pending')
    const [currentPage, setCurrentPage] = useState(0)
    const [commForm, setCommForm] = useState(0)
    const [custompage, setCustompage] = useState(3) // 3 == Without Token, 4 = With Token in URL
    const [userFormData, setUserFormData] = useState(null)
   
    // Get the token from URL
    const params = new URLSearchParams(location.search);
    const token = params.get("tkn");


    // Saved Survey Details
    const [getSavedSurveyData, { data: savedSurveyData, loading: loadingSavedData }] =useLazyQuery(SURVEY_DETAIL, {
        onCompleted: ( savedSurveyData ) => {
            let formDataTemp = []
            //console.log('Got Saved Data');
           // console.log(userFormData)
           // console.log(savedSurveyData)
            {savedSurveyData?.surveyDetail?.answers?.nodes.map((quesans, index)=>(
                formDataTemp[quesans.question_tag]=quesans.answer
            ))}
    
            if(savedSurveyData?.surveyDetail?.status)
                setStatus(savedSurveyData.surveyDetail.status)

            // Set Primary Interest as disabled on Other Interest page
            if(formDataTemp["primary_interest"]!==undefined)
                setPrimaryInterest(formDataTemp["primary_interest"])

            // Set Comm Form based on participate event page option
            if(formDataTemp["participate-event"]!==undefined && formDataTemp["participate-event"]==='¡Sí!') {
               setCommForm(1)
            }

            if(userFormData!==null) {
                Object.keys(userFormData).map((key, val) => {
                    formDataTemp[key] = userFormData[key];
                }) 
            }
           // console.log(formDataTemp)
            // Set Form Data
            setFormData(formDataTemp)

            // Set Current Page
            var tempPage = 0;
            if(savedSurveyData?.surveyDetail?.answered_questions) {
                tempPage = parseInt(savedSurveyData?.surveyDetail?.answered_questions)
                /*if(currentPage===false) {
                    if(tempPage==0)
                        tempPage = 1;
                    
                }*/
            } else {
                //console.log('no data')
                /*if(currentPage===false) // For the Without token URL fetch data on 2nd step
                    tempPage =1;
                else*/
                    tempPage = 0;
            }

            if(currentPage===false) {
                
                if(tempPage>=4) 
                    tempPage = tempPage+3;
                else if(tempPage>=0)
                    tempPage = tempPage+2;

                setCurrentPage(tempPage)
            } else {
                if(tempPage>=4) 
                    tempPage = tempPage+2;
                else if(tempPage>0)
                    tempPage = tempPage+1;

                setCurrentPage(tempPage)
            }

            return tempPage;
        },
        onError: () => {
            //console.log('Get Saved Data Failed')
          /* if(currentPage===false) // For the Without token URL fetch data on 2nd step
            setCurrentPage(1)
           else*/
            setCurrentPage(0)

            return 0;
        }
    });

    // Survey Form Ques/Ans
    const [getFormData,  { data: surveyFormData, loading: loadingFormData }] = useLazyQuery(SURVEY_FORM_DATA);

    // Save Data in WP db 
    const [saveSurvey] = useMutation(SAVE_FORM_MUTATION, {
        onCompleted: ( submittedData ) => {
            //console.log('Form Submitted')
            return true;
        },
        onError: () => {
            //console.log('Form Submission')
            return false;
        }
    });

    // Verify User
    const [verifyACUser, {loading: loadingAPI}] = useMutation(VERIFY_AC_USER_MUTATION, {
        onCompleted: ( verifiedData ) => {

          let tempUserdata = JSON.parse(verifiedData.verifyACUser.data);

            if(tempUserdata.result.userverified===1) {
                setUserVerified(1)
                setVerifiedUserData(tempUserdata.result)
                //console.log('setCustompage')
                setCustompage(4)
               // getSavedSurveyData({variables: { token: token }})
               // getFormData({variables: { id: "cG9zdDo1MDQ=" }}) //cG9zdDo1NzA5 / cG9zdDo1MDQ=
            } else {
                setUserVerified(0)
            }
        },
        onError: () => {
          setUserVerified(0);
        }
    });

    // Validate token using AC through Wp
    useEffect(() => {
       // console.log('call api : ' + new Date().toLocaleString())
        if(userVerified===null && token!==null) {
            //console.log('Verify User');
            verifyACUser({variables: {"clientMutationId": "verify", "token": token}});
        }

        if(token!==null) {
            console.log(token)
            getSavedSurveyData({variables: { token: token }})
        }
        getFormData({variables: { id: "cG9zdDo1NzA5" }}) //Dev:cG9zdDo1NzA5 / Local:cG9zdDo1MDQ= / LIve:cG9zdDoxODg5
       // }
    },[token])

    // Validate Form (Email, Text, WhatsApp)
    const validateForm = values => {

        if (values['participate-event']!=='¡Sí!' && values.acform_email === undefined && values.Text === undefined && values.acform_phone === undefined) {
          return {"status":false, "error": "Please select atleast one option"}
        } else {
            if(values.acform_comm_pref===undefined)
                values.acform_comm_pref = 'Email'
            return {"status":true, "error": ""}
            //errors.acform_comm_pref = 'Required'

            /*if(values.acform_comm_pref==='WhatsApp' && values.acform_phone===undefined) 
                return {"status":false, "error": "Please enter Número de WhatsApp"}
            else
                return {"status":true, "error": ""}*/
        }
    }

    const handlePrimaryInterestDisable = (values) => {
        if(values.primary_interest) {
            setPrimaryInterest(values.primary_interest)
        }
    }

    const handleCommForm = (values) => {
        if(values['participate-event']==='¡Sí!') {
            setCommForm(1)
        } else {
            setCommForm(0)
        }
    }

    const onSubmit = async (values, isLastPage, pageno) => {
       // console.log('status' + status + ', primaryInterest:' + primaryInterest);
        if(Object.keys(values).length===0) { // First Page then skip
            return { "status": true, error: "", "currentpage": currentPage}
        }

        if(isLastPage) {
            const validatedForm = validateForm(values)

            //console.log(validatedForm)
            if(validatedForm.status===false) {
                //console.log('not valid')
                return { "status": false, error: validatedForm.error}
            }
        }

       // console.log('userData')
       // console.log(userData)
        handlePrimaryInterestDisable(values)
        handleCommForm(values)
        var surveydata = {};
        surveydata.clientMutationId = 'survey';
        
        if((userVerified===null || userVerified===0)) { // Second page
           // console.log('in');
            var tempToken = Buffer.from(values.user_email).toString('base64');
            surveydata.token = tempToken;
            var fullName = values.user_fullname.split(' ');
            var firstName = fullName[0];
            var lastName = fullName[fullName.length - 1];
            var userformdata = {"email":values.user_email, "userverified":0, "phoneno":values.user_whatsapp_phone, "first_name":firstName, "last_name":lastName, "member_no":values.user_member_no};
            surveydata.userdata = JSON.stringify(userformdata);

            var userformdata = [];
            userformdata["user_email"] = values.user_email;
            userformdata["user_fullname"] = values.user_fullname;
            userformdata["user_whatsapp_phone"] = values.user_whatsapp_phone;
            userformdata["user_member_no"] = values.user_member_no;
            setUserFormData(userformdata);
        } else {
            surveydata.token = token;

            surveydata.userdata = JSON.stringify(userData);
        }
        surveydata.answers = JSON.stringify(values);
        
        //surveydata.acformdata = JSON.stringify(acFormData);

        var updateAC = isLastPage ? 'yes' : 'no'
        
        if(values['participate-event']!==undefined && values['participate-event']!=='¡Sí!') {
            updateAC = isLastPage-1 ? 'yes':'no'
        }
        console.log(values['participate-event'] + '-' + updateAC)
        surveydata.updateacuser = updateAC

        const isFormSubmitted = await saveSurvey({variables: surveydata});

       // console.log('before' + currentPage)
        if(isFormSubmitted?.data?.saveSurvey?.success===true) {

            if((userVerified===null || userVerified===0) && pageno===1) { 
               setCurrentPage(false);         
               
               getSavedSurveyData({variables: { token:  surveydata.token}})
            }
            
            return { "status": true, "error": "", "currentpage": "false"}
        }
        else   
            return { "status": false, "error": "Unable to upate" }
    }

    
    // Display Error Messages 
    let message = '';
   // if(token===undefined)
   //      message = 'Invalid Token.'
   // else if(userVerified===0)
   //     message = 'Invalid Token.'
    if(status==='complete')
        message = 'You already submitted Survey.'
    else if(status !== 'complete' && userVerified===1 && surveyFormData===null)
        message = 'Invalid URL.';

    //console.log('commForm : ' + commForm + ', currentPage: ' + currentPage)
    //console.log(status + ',currentPage:' + currentPage + ',userVerified:' + userVerified)
    // console.log('formData')
    //console.log('setCurrentPage : ' + currentPage + ', status:' + status)
 
    return (
        <section>
            <main>
                {/*token==null &&
                <div>
                    <div className="logo_main">
                        <img src={logoImage} alt="Logo"/>
                    </div>
                    <div>
                        <SocialShare />
                    </div>
                </div>
                */}
                {(loadingSavedData || loadingFormData || loadingAPI) &&
                    <div className="error-message">Please Wait..</div>}
                {message!=='' &&
                <div>
                    <div className="logo_main">
                        <img src={logoImage} alt="Logo"/>
                    </div>
                    <div className="error-message">
                        {message}
                    </div>
                </div>
                }
                {!(loadingSavedData || loadingFormData || loadingAPI) && status!=='complete' && currentPage!==null && currentPage!==false && surveyFormData && surveyFormData.survey && surveyFormData.survey.surveyForm && (
                    <div className="all_pages_main second_page">
                        <Wizard 
                            page = {currentPage}
                            initialValues={formData}
                            onSubmit={onSubmit}
                            custompage = {custompage}
                            >
                            <Wizard.Page type="Custom" nextText={surveyFormData.survey.surveyForm?.startPage.startButtonText} key='0'>
                                <div className="all_pages_main first_page">
                                    <div className="logo_main">
                                        <img src={logoImage} alt="Logo"/>
                                    </div>
                                    {surveyFormData.survey.surveyForm?.startPage?.surveyTopHeading && 
                                    <h2 className="page_desc" dangerouslySetInnerHTML={{__html: surveyFormData.survey.surveyForm?.startPage?.surveyTopHeading}}></h2>}
                                    <div className="first_screen_img"><img src={surveyFormData.survey.surveyForm?.startPage?.bannerImage?.sourceUrl} alt="" /></div>
                                    <h2 className="page_title">{surveyFormData.survey.surveyForm?.startPage?.surveyHeading}</h2>
                                    <p className="page_desc">{surveyFormData.survey.surveyForm?.startPage?.surveySubHeading}</p>
                                    
                                </div>
                            </Wizard.Page>
                            {(userVerified===null || userVerified===0) &&
                            <Wizard.Page type="userForm" nextText="Responder las preguntas">
                                <div className="all_pages_main first_page">
                                    <div>
                                        <div className="form_element" >
                                            <div className="form_title">
                                                <h2 className="page_title">Para comenzar y conocernos dime</h2>
                                            </div>
                                        </div>
                                        <div className="form_element" >
                                            <label> 
                                                <span>Nombre completo*</span>
                                                <Field name="user_fullname" id="user_fullname" placeholder="" component="input" type="text" required />
                                            </label>
                                        </div>
                                        <div className="form_element" >
                                            <label > 
                                                <span>Email*</span>
                                                <Field name="user_email" id="user_email" placeholder="" component="input" type="email" required/>
                                            </label>
                                        </div>
                                        <div className="form_element" >
                                            <label > 
                                                <span>Numero de miembro*</span>
                                                <Field name="user_member_no" id="user_member_no" placeholder="" component="input" type="text" required/>
                                            </label>
                                        </div>
                                        <div className="form_element" >
                                            <label > 
                                                <span>WhatsApp (incluye el codigo de tu pais)</span>
                                                <Field name="user_whatsapp_phone" id="user_whatsapp_phone" placeholder="" component="input" type="text" required/>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </Wizard.Page>}
                            {surveyFormData.survey.surveyForm.questionAnswer.map((quesAns, index) => {
                                if(quesAns.answerType==='Custom') {
                                    return (<Wizard.Page  type={quesAns.answerType} nextText={quesAns?.customContent?.customButtonText} key={index} >
                                    <div className="all_pages_main first_page">
                                        {quesAns?.bannerImage?.sourceUrl!==null && <div className="first_screen_img"><img src={quesAns?.bannerImage?.sourceUrl} alt="" /></div>}
                                        <h2 className="page_title">{quesAns.customContent.customHeading}</h2>
                                        <p className="page_desc">{quesAns.customContent.customSubheading}</p>
                                    </div>
                                </Wizard.Page>)
                                }
                                else if(quesAns.answerType!=='Custom' &&  quesAns.answerType!=='Communication') {
                                    return (<Wizard.Page  type={quesAns.answerType} nextText={quesAns?.customContent?.customButtonText} key={index} >
                                    <div className={quesAns.styleClass}>
                                        <h2 className="page_title">{quesAns.question}</h2>
                                        {quesAns.questionSubHeading!==null && 
                                            <p className="page_desc">{quesAns.questionSubHeading}</p>}
                                        {quesAns.bannerImage!==null && 
                                            <div className="first_screen_img intersted_screen_img"><img src={quesAns?.bannerImage?.sourceUrl} alt="" />
                                            </div>}
                                        {quesAns.questionInfo!=null &&
                                            <p className="page_desc">{quesAns.questionInfo}</p>
                                        }
                                        <ul className="second_listing">
                                        {quesAns.answers!=null && quesAns.answers.map((ansOption, index) => (
                                            <li className={(primaryInterest === ansOption.text) ? 'second_item primary-parent' : 'second_item'} key={index}>
                                            <label key={index}>
                                                {quesAns.answerType==='Single Select' && 
                                                <Field name={quesAns.questionTag} component="input" type="radio" value={ansOption.text} validate={required}  />}
                                                {quesAns.answerType==='Multi Select' && 
                                                <Field name={quesAns.questionTag} component="input" type="checkbox" value={ansOption.text} validate={required} disabled={quesAns.questionTag === "other_interest" && primaryInterest === ansOption.text}/>}
                                                <div className="listing_img_txt">
                                                    {ansOption.imageType==='Step' && 
                                                    <div className="img_change_count">{index+1}</div>}
                                                    {ansOption.imageType==='Image' && ansOption.image!=null && (
                                                        <>
                                                        {quesAns.questionTag === "other_interest" && primaryInterest === ansOption.text && (
                                                            <span className="primary_interest_disabled">PRIMARY</span>
                                                        )}
                                                        <img src={ansOption?.image?.sourceUrl} alt=""/>
                                                        </>
                                                    )}
                                                    <div className="listing_content">
                                                        <span>{ansOption.text}</span>
                                                        {ansOption.subText!==null && <p>{ansOption?.subText}</p>}
                                                    </div>
                                                </div>
                                            </label>
                                            </li>
                                        ))}
                                        </ul>
                                    </div>
                                    </Wizard.Page>)
                                }
                                else if(quesAns.answerType==='Communication') {
                                    {/*if(commForm===0 ) {
                                        return (<Wizard.Page  type={quesAns.answerType} key={index} >
                                        <div className={quesAns.styleClass}>
                                            <h2 className="page_title">{quesAns.communication.communicationTypeHeading}</h2>
                                            <p className="page_desc">{quesAns.communication.communicationTypeSubheading}</p>
                                            <div className="first_screen_img should_talk_img"><img src={quesAns?.bannerImage?.sourceUrl} alt="" /></div>
                                            <div className="should_talk_field">
                                                {quesAns.communication.communicationType.map((comm, index) => (
                                                    <label className={comm.type} key={index}> 
                                                        <span>{comm.type}</span>
                                                        {comm.enableTextbox===true && comm.type==='Email' &&
                                                        <Field name={comm.commTypeTag} placeholder={comm.type} component="input" type="email" />}
                                                        {comm.enableTextbox===true && comm.type!=='Email' &&
                                                        <Field name={comm.commTypeTag} placeholder={comm.type} component="input" type="text" />}
                                                    </label>
                                                ))}
                                            </div>
                                        </div>
                                                </Wizard.Page>)
                                    } */}
                                    if(commForm===1 ) {
                                        return (<Wizard.Page type={quesAns.answerType} key={index}>
                                            <div className="form-content">
                                                <div className="form_element" >
                                                    <div className="form_title">
                                                        {quesAns.communication.communicationTypeHeading}
                                                        <p>
                                                        {quesAns.communication.communicationTypeSubheading}
                                                        </p>
                                                    </div>
                                                </div>
                                                {quesAns.communication.communicationType.map((comm, index) => {
                                                    
                                                    if(comm.type==='TextBox'){ 
                                                    return(<div className="form_element" key={index} >
                                                        <label className={comm.type} key={index}> 
                                                            <span>{comm.title}</span>
                                                            <Field name={comm.typeTag} id={comm.typeTag} component="input" type="text" />
                                                        </label>
                                                    </div>)
                                                    }
                                                    else if(comm.type==='Single Select') {
                                                    return(<div className="form_element" key={index}>
                                                        <div className="form_title">
                                                            {comm.title}
                                                        </div>
                                                        <div>
                                                            <ul className="second_listing">
                                                                {comm.selectType.map((selectOption, index)=>
                                                                <li className="second_item" key={index}>
                                                                    <label>
                                                                            <Field name={comm.typeTag} id={selectOption.tag} component="input" value={selectOption.title} type="radio" validate={required}/>
                                                                            <div className="listing_img_txt">
                                                                            <div className="listing_content"><span>{selectOption.title}</span>
                                                                            </div>
                                                                        </div>
                                                                    </label>
                                                                </li>
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </div>)
                                                    }
                                                    
                                            })}
                                                {/*<div className="form_element" >
                                                    <label key="1"> 
                                                        <span>Nombre completo</span>
                                                        <Field name="acform_fullname" id="acform_fullname" placeholder="Type your name" component="input" type="text" />
                                                    </label>
                                                </div>
                                                <div className="form_element" >
                                                    <label > 
                                                        <span>Email*</span>
                                                        <Field name="acform_email" id="acform_email" placeholder="Type your email" component="input" type="email" required/>
                                                    </label>
                                                </div>
                                                <div className="form_element" >
                                                    <label > 
                                                        <span>Número de WhatsApp</span>
                                                        <Field name="acform_phone" id="acform_phone" placeholder="Type your phone number" component="input" type="text" />
                                                    </label>
                                                </div>
                                                <div className="form_element" >
                                                    <label> 
                                                        <span>Numero de miembro</span>
                                                        <Field name="acform_member_no" id="acform_member_no" placeholder="" component="input" type="text" />
                                                    </label>
                                                </div>
                                                <div className="form_element" >
                                                    <label> 
                                                        <span>Nombre de tu registrador</span>
                                                        <Field name="acform_reg_name" id="acform_reg_name" placeholder="" component="input" type="text" />
                                                    </label>
                                                </div>
                                                <div className="form_element" >
                                                    <label> 
                                                        <span>Email de tu registrador</span>
                                                        <Field name="acform_reg_email" id="acform_reg_email" placeholder="" component="input" type="text" />
                                                    </label>
                                                </div>
                                                <div className="form_element" >
                                                    <label> 
                                                        <span>Telefono de tu registrador</span>
                                                        <Field name="acform_reg_phone" id="acform_reg_phone" placeholder="" component="input" type="text" />
                                                    </label>
                                                </div>
                                                <div className="form_element" style={{display:"none"}}>
                                                    <label> 
                                                        <span></span>
                                                        <Field name="acform_uniqueid" id="acform_uniqueid" placeholder="" component="input" type="text" />
                                                    </label>
                                                </div>
                                                <div className="form_element" >
                                                    <div className="form_title">
                                                        Communication Preferences*
                                                    </div>
                                                    <input data-autofill="false" type="hidden" id="field[9][]" name="field[9][]" value="~|" />
                                                    <div>
                                                        <ul className="second_listing">
                                                            <li className="second_item" key="0">
                                                                <label>
                                                                    <Field name="acform_comm_pref" id="field_9Email" component="input" value="Email" type="radio" checked />
                                                                    <div className="listing_img_txt">
                                                                        <div className="listing_content"><span>Email</span>
                                                                        </div>
                                                                    </div>
                                                                </label>
                                                            </li>
                                                            <li className="second_item" key="1">
                                                            <label>
                                                                <Field name="acform_comm_pref" id="field_9WhatsApp" component="input" value="WhatsApp" type="radio" />
                                                                <div className="listing_img_txt">
                                                                    <div className="listing_content"><span>WhatsApp</span>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>*/}
                                                <div className="form_element" >
                                                    <div className="form_title">
                                                        Si, estoy de acuerdo en recibir emails.
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </Wizard.Page>)
                                    }
                                }
                            }) }
                            {commForm===0 && (
                            <Wizard.Page type="Completion">
                            <div className="congratulations_main">
                                <h2 className="page_title">{surveyFormData.survey.surveyForm.completionSectionForNoEvent.completionHeading}</h2>
                                <p className="page_desc">{surveyFormData.survey.surveyForm.completionSectionForNoEvent.completionMessage}</p>
                                <p className="page_desc" dangerouslySetInnerHTML={{__html: surveyFormData.survey.surveyForm.completionSectionForNoEvent.completionMessage2}}></p>
                                {/*<div className="first_screen_img congratulations_screen_img">
                                    <img src={surveyFormData.survey.surveyForm.completionSectionForNoEvent?.completionImage?.sourceUrl} alt="" />
                                </div>*/}
                            </div>
                            </Wizard.Page>
                            )}
                            {commForm===1 && (
                            <Wizard.Page type="Completion">
                                <div className="congratulations_main">
                                    <h2 className="page_title">{surveyFormData.survey.surveyForm.completionSectionForInterestedInEvent.completionHeading}</h2>
                                    <div className="first_screen_img congratulations_screen_img">
                                        <img src={surveyFormData.survey.surveyForm.completionSectionForInterestedInEvent?.completionImage?.sourceUrl} alt="" />
                                    </div>
                                    <p className="page_desc">{surveyFormData.survey.surveyForm.completionSectionForInterestedInEvent.completionMessage}</p>
                                    <h2 className="page_desc" style={{fontWeight:"bold"}}>{surveyFormData.survey.surveyForm.completionSectionForInterestedInEvent.completionVideoHeading}</h2>
                                    <div><iframe src={surveyFormData.survey.surveyForm.completionSectionForInterestedInEvent.completionVideo} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{width:"100%"}} title="Bienvenido... !"></iframe></div>
                                </div>
                            </Wizard.Page>
                            )}
                        </Wizard>
                        
                    </div>  

                )}
            </main>
      </section>
  )
}

export default SurveyForm
